import { motion } from "framer-motion"
import { motionVariants } from "../Common/GlobalVars"
import { ArticleItemProps } from "./constants";
import Image from "next/image";
import Link from "next/link";


const HorizontalArticle = ({ article, animated }: { article: ArticleItemProps, animated: boolean }) => {

  if (animated) {
    return (
      <div>
        <Link href={`/mamapedia/${article.slug}`}>
          <div className="flex">
            <Image
              src={article.main_image}
              alt={article.title}
              width={1024}
              height={700}
              className="w-1/3 h-[105px] !object-cover rounded-lg"
            />
            <div className="px-4 text-sm font-bold text-black font-poppins">
              {article.title}
              <div className="flex items-center mt-2 space-x-1">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/pencil.png"}
                  alt="pencil icon"
                  quality={100}
                  width={16}
                  height={16}
                />
                <p className="text-[10px] font-bold text-mooimom">{article.category}</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <Link href={`/mamapedia/${article.slug}`}>
        <div className="flex">
          <Image
            src={article.main_image}
            alt={article.title}
            width={1024}
            height={700}
            className="w-1/3 h-[105px] !object-cover rounded-lg"
          />
          <div className="px-4 text-sm font-bold text-black font-poppins">
            {article.title}
            <div className="flex items-center mt-2 space-x-1">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/pencil.png"}
                alt="pencil icon"
                quality={100}
                width={16}
                height={16}
              />
              <p className="text-[10px] font-bold text-mooimom">{article.category}</p>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  )
}

export default HorizontalArticle

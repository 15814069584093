import { motion } from "framer-motion";
import { ArticleItemProps } from "./constants";
import { motionVariants } from "../Common/GlobalVars";
import Image from "next/image";
import Link from "next/link";


interface MinArticleProps {
  article: ArticleItemProps;
  option: string;
}

const MinArticle = (props: MinArticleProps) => {
  const { article, option } = props;

  if (article === null || article === undefined) {
    return (<></>)
  }

  if (option === "A") {
    return (
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
        className="font-poppins"
      >
        <Link href={`/mamapedia/${article && article.slug ? article.slug : "#"}`}>
          <Image
            src={article.main_image}
            alt={article.title}
            width={1024}
            height={700}
            className="!object-cover w-full h-[200px] rounded-lg"
          />
          <p className="w-4/5 mt-4 text-sm font-bold text-black">{article.title}</p>
          <div className="flex items-center w-4/5 mt-2 space-x-1">
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/pencil.png"}
              alt="pencil icon"
              quality={100}
              width={16}
              height={16}
            />
            <p className="text-[10px] text-mooimom font-bold">{article.category}</p>
          </div>
        </Link>
      </motion.div>
    )
  } else {
    return (
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
        variants={motionVariants}
        className="relative mb-20"
      >
        <Link href={`/mamapedia/${article && article.slug ? article.slug : "#"}`}>
          <Image
            src={article.main_image}
            alt={article.title}
            width={1024}
            height={700}
            className="w-full h-[200px] object-cover top-0 left-0 right-0"
          />
          <div className="bg-white rounded-2xl font-poppins absolute top-[80%] w-[75%] left-1/2 -translate-x-1/2 p-4 shadow-md">
            <p className="text-xs text-mooimom font-bold mb-[2px]">{article.category}</p>
            <p className="text-sm text-black font-bold mb-[2px] line-clamp-3 text-ellipsis h-[60px]">{article.title}</p>
          </div>
        </Link>
      </motion.div>
    )
  }
}

export default MinArticle

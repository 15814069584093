import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { motionVariants } from '../Common/GlobalVars';


const stages = [
  {
    title: "Mom Support",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/mom-support.webp",
    image_mobile: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/mom-support-mb.webp",
    link: "/product-category/rekomendasi-produk-custom-mom-support",
  },
  {
    title: "Breastfeeding",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/breastfeeding.webp",
    image_mobile: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/breastfeeding-mb.webp",
    link: "/product-category/rekomendasi-produk-custom-breastfeeding",
  },
  {
    title: "Outdoor",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/outdoor.webp",
    image_mobile: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/outdoor-mb.webp",
    link: "/product-category/rekomendasi-produk-custom-outdoor",
  },
  {
    title: "Daily Wear",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/daily-wear.webp",
    image_mobile: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/daily-wear-mb.webp",
    link: "/product-category/rekomendasi-produk-custom-daily-wear",
  },
  {
    title: "Play Time",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/play-time.webp",
    image_mobile: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/play-time-mb.webp",
    link: "/product-category/rekomendasi-produk-custom-play-time",
  },
  {
    title: "Dinner Time",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/dinner-time.webp",
    image_mobile: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/dinner-time-mb.webp",
    link: "/product-category/rekomendasi-produk-custom-dinner-time",
  },
]

interface StageProps {
  option: string;
}

const Stage = (props: StageProps) => {
  return (
    <motion.div
      className="container my-12 font-poppins"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      {props.option === "A" ? (
        <>
          <h3 className="text-xl md:text-[28px] font-bold text-center mx-[43px] mb-6 md:mb-12 text-black">Products to Support Every Journey</h3>
          <div className="grid grid-cols-3 gap-x-4 gap-y-4 md:flex">
            {stages.map((stage, index) => (
              <div key={index}>
                <Link href={stage.link}>
                  <div className="mt-4 md:hidden">
                    <Image
                      src={stage.image_mobile}
                      alt={stage.title}
                      quality={100}
                      width={800}
                      height={800}
                      className="rounded shadow-md"
                    />
                    <div className="mx-2 mt-2 text-xs text-center text-black">{stage.title}</div>
                  </div>
                  <div className="relative hidden w-full md:block">
                    <Image
                      src={stage.image}
                      alt={stage.title}
                      quality={100}
                      width={600}
                      height={841}
                      className="object-cover w-full h-full rounded shadow"
                    />
                    <div className="absolute bottom-5 left-3 right-3">
                      <p className="text-lg font-bold text-white">{stage.title}</p>
                      <p className="mt-2 text-xs font-light text-white underline">Shop Now</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="grid grid-cols-3 mt-8 md:grid-cols-6 gap-x-6 md:gap-x-8 gap-y-4">
          {stages.map((stage, index) => (
            <div key={index}>
              <Link href={stage.link}>
                <Image
                  src={stage.image}
                  alt={stage.title}
                  quality={100}
                  width={800}
                  height={800}
                  className="rounded-full shadow-md md:shadow"
                />
                <div className="mx-2 mt-2 text-xs font-medium text-center md:text-sm md:mt-6">{stage.title}</div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </motion.div>
  )
}

export default Stage

import Image from "next/image"
import AlertDialog from "../Utils/AlertDialog"
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { motionVariants } from "../Common/GlobalVars";


const benefitList = [
  {
    text_image: "shipping-fee-text.png",
    image: "shipping-fee.png",
    benefit: "shipping_fee",
    title: "0 Rupiah Shipping Fee*",
    message: "Belanja tanpa khawatir biaya kirim, dapatkan penawaran bebas ongkos kirim untuk setiap pembelanjaan"
  },
  {
    text_image: "new-product-text.png",
    image: "new-product.png",
    benefit: "new_product",
    title: "Get the New Product First",
    message: "Jadilah yang pertama mendapatkan produk terbaru MOOIMOM dan brand favorit lainnya!"
  },
  {
    text_image: "cashback-text.png",
    image: "cashback.png",
    benefit: "cashback",
    title: "Cashback Reward Up To 100,000*",
    message: "Dapatkan cashback point hingga 100,000 untuk setiap pembelanjaan yang Moms lakukan"
  },
  {
    text_image: "product-offer-text.png",
    image: "product-offer.png",
    benefit: "product_offer",
    title: "Exclusive Product Offer",
    message: "Akses promo dan produk eksklusif spesial untuk membership website & app"
  },
  {
    text_image: "free-gift-text.png",
    image: "affiliate.png",
    benefit: "free-gift",
    title: "A Special Gift with Every Purchase",
    message: "Terhemat dan banyak hadiah untuk setiap pembelanjaan yang Mom lakukan di Website & App"
  }
]

const Benefit = () => {
  const [open, setOpen] = useState(false);
  const [tittle, setTittle] = useState("");
  const [message, setMessage] = useState("");

  const handleClick = (benefit: string) => {
    const benefitData = benefitList.find(item => item.benefit === benefit);

    if (benefitData) {
      setTittle(benefitData.title);
      setMessage(benefitData.message);
    }
  }

  useEffect(() => {
    if (message.length > 0) {
      setOpen(true);
    }
  }, [message])

  useEffect(() => {
    if (!open) {
      setTittle("");
      setMessage("");
    }
  }, [open])

  return (
    <motion.div
      className="my-16 md:container"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <div className="ml-6 text-base font-bold text-black md:text-2xl font-poppins md:ml-0">Benefit Shop in Website</div>
      <div className="flex px-6 pt-6 pb-4 space-x-4 overflow-x-auto flex-nowrap md:hidden scrollbar-hide">
        <div className="space-y-4">
          {benefitList.slice(0, 2).map((item, index) => (
            <div
              key={index}
              className="relative min-w-[300px] h-40 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] cursor-pointer"
              onClick={() => handleClick(item.benefit)}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
                alt={item.title + " text"}
                width={200}
                height={100}
                className="absolute top-0 left-0"
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
                alt={item.title}
                width={140}
                height={140}
                className="absolute bottom-0 right-0"
              />
            </div>
          ))}
        </div>
        <div className="space-y-4">
          {benefitList.slice(2, 3).map((item, index) => (
            <div
              key={index}
              className="relative min-w-[300px] rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] h-[336px] cursor-pointer"
              onClick={() => handleClick(item.benefit)}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
                alt={item.title + " text"}
                width={300}
                height={150}
                className="absolute top-0 left-0"
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
                alt={item.title}
                width={200}
                height={200}
                className="absolute bottom-0 right-0"
              />
            </div>
          ))}
        </div>
        <div className="space-y-4">
          {benefitList.slice(3, 5).map((item, index) => (
            <div
              key={index}
              className="relative min-w-[300px] h-40 rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] cursor-pointer"
              onClick={() => handleClick(item.benefit)}
            >
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
                alt={item.title + " text"}
                width={200}
                height={100}
                className="absolute top-0 left-0"
              />
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
                alt={item.title}
                width={140}
                height={140}
                className="absolute bottom-0 right-0"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="hidden grid-cols-3 gap-6 pt-6 pb-4 md:grid md:grid-rows-2 md:grid-flow-col">
        {benefitList.map((item, index) => (
          <div
            key={index}
            className={`relative w-full ${index !== 2 ? "h-40" : "h-84 row-span-2"} rounded shadow-[0_2px_12px_2px_rgb(0,0,0,0.1)] cursor-pointer`}
            onClick={() => handleClick(item.benefit)}
          >
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.text_image}`}
              alt={item.title + " text"}
              width={index !== 2 ? 200 : 300}
              height={index !== 2 ? 100 : 150}
              className="absolute top-0 left-0"
            />
            <Image
              src={process.env.NEXT_PUBLIC_STATIC_SITE + `/images/benefit/${item.image}`}
              alt={item.title}
              width={index !== 2 ? 140 : 200}
              height={index !== 2 ? 140 : 200}
              className="absolute bottom-0 right-0"
            />
          </div>
        ))}
      </div>
      <AlertDialog open={open} setOpen={setOpen} message={message} tittle={tittle} />
    </motion.div>
  )
}

export default Benefit

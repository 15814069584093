import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { motionVariants } from '../Common/GlobalVars';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";


const categories = [
  {
    title: "All Products",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/all-product.webp",
    link: "/product-category/rekomendasi-produk-custom-all-products",
  },
  {
    title: "Breast Pump",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/breast-pump.png",
    link: "/product-category/rekomendasi-produk-custom-breast-pump",
  },
  {
    title: "Corset",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/korset.png",
    link: "/product-category/rekomendasi-produk-custom-corset",
  },
  {
    title: "Hipseat",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/hipseat.png",
    link: "/product-category/rekomendasi-produk-custom-hipseat",
  },
  {
    title: "Bag",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/bag.png",
    link: "/product-category/rekomendasi-produk-custom-bag",
  },
  {
    title: "Tableware",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/tableware.png",
    link: "/product-category/rekomendasi-produk-custom-tableware",
  },
  {
    title: "Toys",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/toys.png",
    link: "/product-category/rekomendasi-produk-custom-toys",
  },
  {
    title: "Clothing",
    image: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/stage/clothing.png",
    link: "/product-category/rekomendasi-produk-custom-clothing",
  }
]

const ShopByCategory = () => {
  const viewport = Cookies.get('viewport');

  return (
    <motion.div
      className="md:container my-12 font-poppins"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <h3 className="text-base md:text-2xl font-bold mb-6 md:mb-12 text-black px-6 md:px-0">Shop by Category</h3>
      <Swiper
        spaceBetween={viewport === "desktop" ? 24 : 20}
        centeredSlides={false}
        loop={false}
        slidesPerView={viewport === "desktop" ? 6.4 : 3.4}
        className="!pl-6 md:!pl-0"
      >
        {categories.map((category, index) => (
          <SwiperSlide key={index} className="w-20 min-w-20 md:w-[130px] md:min-w-[130px]">
            <Link href={category.link}>
              <div className="bg-gradient-to-r from-shopByCategoryStartBg to-shopByCategoryEndBg rounded-full shadow-md">
                <Image
                  src={category.image}
                  alt={category.title}
                  quality={100}
                  width={400}
                  height={400}
                  className="rounded-full"
                />
              </div>
              <div className="mx-2 mt-6 text-xs md:text-sm font-medium text-center text-black">{category.title}</div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  )
}

export default ShopByCategory

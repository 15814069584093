import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { motionVariants } from '../Common/GlobalVars';
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from 'js-cookie';

import "swiper/css";


const brands = [
  {
    "src": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/featured-brands/mooimom.jpg",
    "alt": "MOOIMOM brand",
    "link": "/product-category/brand?brand=MOOIMOM",
    "logo": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo/mooimom-white.png"
  },
  {
    "src": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/featured-brands/mugu.jpg",
    "alt": "mugu brand",
    "link": "/product-category/brand?brand=MUGU",
    "logo": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo/mugu-white.png"
  },
  {
    "src": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/featured-brands/tweeling.jpg",
    "alt": "Tweeling brand",
    "link": "/product-category/brand?brand=Tweeling",
    "logo": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo/tweeling-white.png"
  },
  {
    "src": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/featured-brands/nero-bianco.jpg",
    "alt": "Nero Bianco brand",
    "link": "/product-category/brand?brand=Nero%20Bianco",
    "logo": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo/nero-bianco-white.png"
  },
  {
    "src": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/featured-brands/mooikind.jpg",
    "alt": "mooikind brand",
    "link": "/product-category/brand?brand=mooikind",
    "logo": process.env.NEXT_PUBLIC_STATIC_SITE + "/images/logo/mooikind-white.png"
  },
]

const FeatureBrands = () => {
  const viewport = Cookies.get('viewport');

  return (
    <motion.div
      className="m-auto mb-12 md:container font-poppins"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <h2 className="pl-6 mb-6 text-base font-bold text-black md:text-2xl md:pl-0">Featured Brands</h2>
      <Swiper
        spaceBetween={viewport === "desktop" ? 28 : 16}
        centeredSlides={false}
        loop={false}
        slidesPerView={viewport === "desktop" ? 2.3 : 1.2}
        className="!pl-6 md:!pl-0"
      >
        {brands.map((brand, index) => (
          <SwiperSlide key={index}>
            <Link href={brand.link} key={index}>
              <div className="relative w-full md:min-w-[400px]">
                <Image
                  src={brand.src}
                  alt={brand.alt}
                  quality={100}
                  width={600}
                  height={327}
                  className="rounded"
                />
                <Image
                  src={brand.logo}
                  alt={brand.alt}
                  quality={100}
                  width={600}
                  height={266}
                  className="absolute left-6 bottom-6 w-[120px] z-10"
                />
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
    
  )
}

export default FeatureBrands

import Image from 'next/image';
import { useState } from "react";
import * as Dialog from '@radix-ui/react-dialog';


const Assistant = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <div className="fixed bottom-10 right-4 md:right-8 z-[30]">
          <Image
            src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/assistant.png"}
            alt="assistant icon"
            quality={100}
            width={60}
            height={60}
            className={`cursor-pointer h-[60px] w-[60px]`}
          />
        </div>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-mobileMask md:bg-desktopMask bg-opacity-50 md:bg-opacity-40 z-[60]" />
        <Dialog.Content className="fixed bottom-0 md:bottom-auto md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 bg-white p-8 md:px-20 md:py-12 rounded-t-lg md:rounded-lg w-full md:w-fit md:max-w-1/2 mx-auto z-[65] outline-none focus:ring-transparent">
          <Dialog.Title />
          <Dialog.Description className="text-attributeTitle font-poppins">
            <div className="flex items-center mb-8 space-x-4 flex-nowrap">
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/close.png"}
                alt="close icon"
                width={16}
                height={16}
                quality={100}
                onClick={() => setIsOpen(false)}
                className="m-1 cursor-pointer"
              />
              <p className="font-semibold text-base w-[200px]">Customer Service</p>
            </div>
            <div
              className="flex items-center justify-between space-x-4 cursor-pointer"
              onClick={() => {
                window.open("https://wa.me/6281288446533");
                setIsOpen(false);
              }}
            >
              <div className="flex items-center space-x-4 flex-nowrap">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/whatsapp-icon.png"}
                  alt="close icon"
                  width={24}
                  height={24}
                  quality={100}
                />
                <p className="font-medium text-sm w-[200px]">Whatsapp Live Chat</p>
              </div>
              <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow-down.png"}
                  alt="arrow icon"
                  width={12}
                  height={12}
                  quality={100}
                  className="-rotate-90"
                />
            </div>
            {/* <hr className="my-4 bg-reviewBg" />
            <div
              className="flex items-center justify-between space-x-4 cursor-pointer"
              onClick={() => {
                location.href = "";
                setIsOpen(false);
              }}
            >
              <div className="flex items-center space-x-4 flex-nowrap">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/customer-service-icon.png"}
                  alt="close icon"
                  width={24}
                  height={24}
                  quality={100}
                />
                <p className="font-medium text-sm w-[200px]">Consultation Center</p>
              </div>
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow-down.png"}
                alt="arrow icon"
                width={12}
                height={12}
                quality={100}
                className="-rotate-90"
              />
            </div> */}
            <hr className="my-4 bg-reviewBg" />
            <div
              className="flex items-center justify-between space-x-4 cursor-pointer"
              onClick={() => {
                window.open("https://www.facebook.com/mooimomid/");
                setIsOpen(false);
              }}
            >
              <div className="flex items-center space-x-4 flex-nowrap">
                <Image
                  src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/facebook-icon.png"}
                  alt="close icon"
                  width={24}
                  height={24}
                  quality={100}
                />
                <p className="font-medium text-sm w-[200px]">Facebook</p>
              </div>
              <Image
                src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/arrow-down.png"}
                alt="arrow icon"
                width={12}
                height={12}
                quality={100}
                className="-rotate-90"
              />
            </div>
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default Assistant;

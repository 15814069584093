import { CustomVariantItemProps, MinProductItemProps } from "../Product/constants";
import OurPick from "../Utils/OurPick";
import { OurPicksItemProps } from "../Utils/constants";


interface OurPickListItemProps {
  our_picks: Array<OurPicksItemProps>;
  setSelectProduct: (product: MinProductItemProps) => void;
  selectedCustomVariant: CustomVariantItemProps | null;
  setSelectedCustomVariant: (variant: CustomVariantItemProps | null) => void;
  fromName: string;
  setFromName: (value: string) => void;
  toName: string;
  setToName: (value: string) => void;
  toEmail: string;
  setToEmail: (value: string) => void;
  toMobile: string;
  setToMobile: (value: string) => void;
  toMessage: string;
  setToMessage: (value: string) => void;
  pregnancy: string;
  setPregnancy: (value: string) => void;
  duDate: Date | null;
  setDuDate: (value: Date | null) => void;
  childName: string;
  setChildName: (value: string) => void;
  childAge: number | null;
  setChildAge: (value: number | null) => void;
  childGender: string;
  setChildGender: (value: string) => void;
}

const OurPickList = (props: OurPickListItemProps) => {
  const { our_picks } = props;

  return (
    <div className="mb-12 md:container md:mb-20">
      {our_picks.filter((pick) => pick.product_list !== null && pick.product_list.length > 0).map((pick, index) => (
        <OurPick
          key={index}
          tittle={pick.tittle}
          link={pick.link}
          image={pick.image}
          banner_list={pick.banner_list}
          product_list={pick.product_list}
          attrId={null}
          customName={null}
          expandColor={false}
          showBorder={false}
          setSelectProduct={props.setSelectProduct}
          selectedCustomVariant={props.selectedCustomVariant}
          setSelectedCustomVariant={props.setSelectedCustomVariant}
          fromName={props.fromName}
          setFromName={props.setFromName}
          toName={props.toName}
          setToName={props.setToName}
          toEmail={props.toEmail}
          setToEmail={props.setToEmail}
          toMobile={props.toMobile}
          setToMobile={props.setToMobile}
          toMessage={props.toMessage}
          setToMessage={props.setToMessage}
          pregnancy={props.pregnancy}
          setPregnancy={props.setPregnancy}
          duDate={props.duDate}
          setDuDate={props.setDuDate}
          childName={props.childName}
          setChildName={props.setChildName}
          childAge={props.childAge}
          setChildAge={props.setChildAge}
          childGender={props.childGender}
          setChildGender={props.setChildGender}
          pwp_name={pick.pwp_name}
          pwp={pick.pwp}
        />
      ))}
    </div>
  )
}

export default OurPickList
